import React from 'react';

import { Command } from 'cmdk';
import { useNavigate } from 'react-router-dom';

import { APIQueryConstant } from 'constants/api.constants';
import { IAssetListDetails } from 'services/asset-service/asset.modal';
import { IGlobalSearchModule } from 'services/common/common.modal';
import { JobDetails } from 'services/job-service/job.modal';
import { IPartsList } from 'services/parts/parts.modal';
import {
  ILocationDetails,
  ILocationGroupDetails,
  IUserDetailsResponse,
} from 'services/settings-service/settings.modal';
import { ITelematicDevices } from 'services/telematics/telematics.modal';
import { addUrlId } from 'utils/commonUtils';

import { IModuleConfig, modulesConfig } from './config';
import ModuleListView from './ModuleListView';
import { useRecentlyViewed } from './useRecentlyViewed';

export const getSearchModuleKey = (d: unknown, selectedModule: IGlobalSearchModule): string => {
  switch (selectedModule) {
    case IGlobalSearchModule.assets:
      return (d as IAssetListDetails).assetId;

    case IGlobalSearchModule.telematics:
      return (d as ITelematicDevices).id;

    case IGlobalSearchModule.jobs:
      return (d as JobDetails).jobId;

    case IGlobalSearchModule.parts:
      return (d as IPartsList).partId;

    case IGlobalSearchModule.organisation_locations:
      return (d as ILocationDetails).locationId;

    case IGlobalSearchModule.location_groups:
      return (d as ILocationGroupDetails).locationGroupId;

    case IGlobalSearchModule.users:
      return (d as IUserDetailsResponse).userId;

    default:
      return '';
  }
};

interface RecentlyViewedProps {
  selectedModule: IGlobalSearchModule | null;
  onModuleSelect: (module: IGlobalSearchModule) => void;
  resetSearch: (value?: string) => void;
  closeSearch: () => void;
}

function RecentlyViewed({
  selectedModule,
  onModuleSelect,
  resetSearch,
  closeSearch,
}: RecentlyViewedProps): JSX.Element | null {
  const { getRecentlyViewedData, getAllRecentlyViewedData, key, setRecentlyViewedData } =
    useRecentlyViewed();

  const fullList = getAllRecentlyViewedData();

  const moduleDataPresent = selectedModule ? !!getRecentlyViewedData(selectedModule).length : true;
  const navigate = useNavigate();

  if (!moduleDataPresent) {
    return null;
  }

  if (!localStorage.getItem(key)) {
    return null;
  }

  const getConfig = (module: IGlobalSearchModule): IModuleConfig | undefined =>
    modulesConfig.find((d) => d.module === module);

  const handleRedirection = (value: string, data: unknown, m: IGlobalSearchModule): void => {
    const { detailsRoute, listRoute } = getConfig(m) ?? {};
    onModuleSelect(m);
    (document.getElementsByClassName('search-input')[0] as HTMLInputElement)?.blur();
    if (!data) {
      closeSearch();
      resetSearch(value);
      setRecentlyViewedData({ showAllQuery: value }, m);
      navigate(`${listRoute}?${APIQueryConstant.searchQuery}=${encodeURIComponent(value)}`);
      return;
    }
    if (detailsRoute) {
      setRecentlyViewedData(data, m);
      closeSearch();
      resetSearch();
      navigate(addUrlId(detailsRoute, value));
    }
  };

  return (
    <Command.Group heading='Recently Viewed'>
      {/* <Command.Empty>No results found.</Command.Empty> */}
      {selectedModule
        ? getRecentlyViewedData(selectedModule).map((d) => (
            <ModuleListView
              key={getSearchModuleKey(d.data, selectedModule)}
              handleRedirection={handleRedirection}
              details={d.data}
              module={selectedModule}
              isRecentView
            />
          ))
        : fullList.map((d) => {
            return (
              <ModuleListView
                key={getSearchModuleKey(d.data, d.module)}
                handleRedirection={handleRedirection}
                details={d.data}
                module={d.module}
                isRecentView
              />
            );
          })}
    </Command.Group>
  );
}

export default RecentlyViewed;
