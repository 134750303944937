import { useCallback, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Routes, Route, Navigate } from 'react-router-dom';

import AuthLayout, { AuthLayoutWithoutSideNav } from 'app/auth-layout/AuthLayout';
import NoPermissionIssueInfo from 'common/layout/NoPermissionIssueInfo';
import NotFound from 'common/pages/404/NotFound';
import PageLoader from 'common/pages/PageLoader/PageLoader';
import LocationDetailsLoadable from 'components/settings/location-details/LocationDetailsLoadable';
import ROUTES from 'constants/routes';
import AssetDetailsPageLoadable from 'features/assets/asset-details/AssetsDetailsLoadable';
import AssetHistoryLoadable from 'features/assets/asset-details/tabs/asset-history/AssetHistoryLoadable';
import AssetJobHistoryLoadable from 'features/assets/asset-details/tabs/asset-job-history/AssetJobHistoryLoadable';
import AssetMainDetailsLoadable from 'features/assets/asset-details/tabs/asset-main-details/AssetMainDetailsLoadable';
import AssetsLoadable from 'features/assets/AssetsLoadable';
import NoPermissionLoadable from 'features/common/no-permission/NoPermissionLoadable';
import JobDetailsLoadable from 'features/jobs/job-details/JobDetailsLoadable';
import JobsLoadable from 'features/jobs/JobsLoadable';
import OverviewLoadable from 'features/overview/OverviewLoadable';
import PartsDetailsLoadable from 'features/parts/PartsDetails/PartsDetailsLoadable';
import PartsLoadable from 'features/parts/PartsLoadable';
import ReportsLoadable from 'features/reports/ReportsLoadable';
import AdditionalFieldsLoadable from 'features/settings/additional_fields/AdditionalFieldsLoadable';
import AssetConfigLoadable from 'features/settings/asset-config/AssetConfigLoadable';
import GroupDetailsLoadable from 'features/settings/groups/GroupDetails/GroupDetailsLoadable';
import GroupsLoadable from 'features/settings/groups/GroupsLoadable';
import AddJobActivityLoadable from 'features/settings/job-activity/AddJobActivity/AddJobActivityLoadable';
import CategoriesLoadable from 'features/settings/jobs/categories/CategoriesLoadable';
import JobActivityDetailsLoadable from 'features/settings/jobs/job-activity/job-activity-details/JobActivityDetailsLoadable';
import JobActivityLoadable from 'features/settings/jobs/job-activity/JobActivityLoadable';
import JobTypesConfigLoadable from 'features/settings/jobs/job-types-config/JobTypesConfigLoadable';
import LocationsLoadable from 'features/settings/locations/LocationsLoadable';
import OrganisationDetailsLoadable from 'features/settings/organisation/OrganisationDetails/OrganisationDetailsLoadable';
import UserAssetHistoryLoadable from 'features/settings/users/user-details/tabs/history/asset-history/UserAssetHistoryLoadable';
import UserDetailsPageLoadable from 'features/settings/users/user-details/tabs/user-details-tab/UserDetailsPageLoadable';
import UserDetailsLoadable from 'features/settings/users/user-details/UserDetailsLoadable';
import UsersLoadable from 'features/settings/users/UsersLoadable';
import TelematicsDetailsLoadable from 'features/telematics/telematics-details/TelematicsDetailsLoadable';
import TelematicsLoadable from 'features/telematics/TelematicsLoadable';
import UserProfileCompleteLoadable from 'features/user-profile-complete/UserProfileCompleteLoadable';
import UserProfileLoadable from 'features/user-profile/UserProfileLoadable';
import withAuthHoc from 'hoc/auth/AppAuthHoc';
import NewUserNavigation from 'hooks/NewUserNavigation';
import { useFeatures } from 'hooks/useFeatures';
import { ICommonApiResponse } from 'services/common/common.modal';
import { getFeatures } from 'services/features-service/features.service';
import { INewUserDetails } from 'services/user-service/user.modal';
import { getAdditionalFields, getLoggedInUserDetails } from 'services/user-service/user.service';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getUserPermission } from 'store/reducers/userSlice';

function AppRoute(): JSX.Element {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth0();
  const permissions = useAppSelector(getUserPermission);
  const [additionFieldLoader, updateAdditionalFieldLoader] = useState(true);
  const [featuresLoader, setFeaturesLoader] = useState(true);

  const { data: features } = useFeatures();

  const updateUserDetails = useCallback(async (): Promise<void> => {
    try {
      const currentUserDetails = await getLoggedInUserDetails();
      if (currentUserDetails) {
        const { data } = currentUserDetails as unknown as ICommonApiResponse<INewUserDetails>;
        dispatch({ type: 'user/setLoggedInUserDetails', payload: data });
      }
    } catch (error) {
      dispatch({ type: 'user/setLoggedInUserDetails', payload: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateUserDetails();
    getFeatures()
      .then((featuresPayload) => {
        dispatch({ type: 'features/setFeatures', payload: featuresPayload });
      })
      .finally(() => {
        setFeaturesLoader(false);
      });
    getAdditionalFields()
      .then((additionalFields) => {
        dispatch({ type: 'additionalFields/setAdditionalFields', payload: additionalFields });
      })
      .finally(() => {
        updateAdditionalFieldLoader(false);
      });
  }, [updateUserDetails, dispatch]);

  if ((permissions === null && isAuthenticated) || additionFieldLoader || featuresLoader) {
    return <PageLoader />;
  }

  if (permissions && !permissions.length && isAuthenticated) {
    return <NoPermissionIssueInfo />;
  }

  return (
    <>
      {/* if we use custom hook. it's creating re-render issue so added component */}
      <NewUserNavigation />
      <Routes>
        {/* user routes */}

        <Route path={ROUTES.USER.PROFILE_COMPLETE} element={withAuthHoc(AuthLayoutWithoutSideNav)}>
          <Route index element={<UserProfileCompleteLoadable />} />
        </Route>

        <Route path={ROUTES.USER.PROFILE_DETAILS} element={withAuthHoc(AuthLayoutWithoutSideNav)}>
          <Route index element={<UserProfileLoadable />} />
        </Route>

        <Route path={ROUTES.HOME} element={withAuthHoc(AuthLayout)}>
          <Route index element={<Navigate replace to={ROUTES.USER.OVERVIEW} />} />
          <Route path={ROUTES.USER.OVERVIEW} element={withAuthHoc(OverviewLoadable)} />
          {features?.data?.features?.reports_url && (
            <Route path={ROUTES.USER.REPORTS} element={withAuthHoc(ReportsLoadable)} />
          )}
          <Route path={ROUTES.USER.NO_PERMISSION} element={withAuthHoc(NoPermissionLoadable)} />

          <Route
            path={`${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.ADDITIONAL_FIELDS}`}
            element={withAuthHoc(AdditionalFieldsLoadable)}
          />
          <Route
            path={`${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.ADDITIONAL_FIELDS}/:entity`}
            element={withAuthHoc(AdditionalFieldsLoadable)}
          />

          <Route
            path={`${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.GROUPS}`}
            element={withAuthHoc(GroupsLoadable)}
          />
          <Route
            path={`${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.GROUPS_DETAILS}`}
            element={withAuthHoc(GroupDetailsLoadable)}
          />

          <Route
            path={`${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.ASSET_CONFIG}`}
            element={withAuthHoc(AssetConfigLoadable)}
          />
          <Route
            path={`${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.JOB_TYPES}`}
            element={withAuthHoc(JobTypesConfigLoadable)}
          />

          <Route
            path={`${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.JOB_ACTIVITY}`}
            element={withAuthHoc(JobActivityLoadable)}
          />

          <Route
            path={`${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.CATEGORIES}`}
            element={withAuthHoc(CategoriesLoadable)}
          />

          <Route
            path={`${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.ADD_JOB_ACTIVITY}`}
            element={withAuthHoc(AddJobActivityLoadable)}
          />

          <Route
            path={`${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.JOB_ACTIVITY_DETAILS}`}
            element={withAuthHoc(JobActivityDetailsLoadable)}
          />

          <Route
            path={`${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.EDIT_JOB_ACTIVITY}`}
            element={withAuthHoc(AddJobActivityLoadable)}
          />
          <Route
            path={`${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.ASSET_CONFIG}/:entity`}
            element={withAuthHoc(AssetConfigLoadable)}
          />
          <Route
            path={`${ROUTES.USER.SETTINGS.LOCATIONS}`}
            element={withAuthHoc(LocationsLoadable)}
          />
          <Route path={`${ROUTES.USER.PROFILE}`} element={withAuthHoc(UserProfileLoadable)} />
          <Route
            path={`${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.ORGANISATIONS_DETAILS}`}
            element={withAuthHoc(OrganisationDetailsLoadable)}
          />
          <Route
            path={`${ROUTES.USER.SETTINGS.LOCATION_DETAILS}`}
            element={withAuthHoc(LocationDetailsLoadable)}
          />

          <Route path={`${ROUTES.USER.ASSETS.BASE_PATH}`} element={withAuthHoc(AssetsLoadable)} />
          <Route path={`${ROUTES.USER.TELEMATICS.BASE_PATH}`}>
            <Route
              path={`${ROUTES.USER.TELEMATICS.TELEMATICS_DETAILS}`}
              element={withAuthHoc(TelematicsDetailsLoadable)}
            />
            <Route index element={withAuthHoc(TelematicsLoadable)} />
          </Route>
          <Route path={`${ROUTES.USER.JOBS.BASE_PATH}`} element={withAuthHoc(JobsLoadable)} />

          <Route
            path={`${ROUTES.USER.JOBS.JOB_DETAILS}`}
            element={withAuthHoc(JobDetailsLoadable)}
          />

          <Route
            path={`${ROUTES.USER.PARTS.PARTS_DETAILS}`}
            element={withAuthHoc(PartsDetailsLoadable)}
          />

          <Route path={`${ROUTES.USER.PARTS.BASE_PATH}`}>
            <Route index element={withAuthHoc(PartsLoadable)} />
            <Route path=':type' element={withAuthHoc(PartsLoadable)} />
          </Route>

          <Route
            path={`${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.USER_DETAILS}`}
            element={withAuthHoc(UserDetailsLoadable)}
          >
            <Route
              path={`${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.USER_DETAILS}`}
              element={withAuthHoc(UserDetailsPageLoadable)}
            />

            <Route
              path={`${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.USER_DETAILS}${ROUTES.USER.SETTINGS.USERS_ASSIGNMENT_HISTORY}`}
              element={withAuthHoc(UserAssetHistoryLoadable)}
            />
          </Route>

          <Route
            path={`${ROUTES.USER.ASSETS.ASSET_DETAILS}`}
            element={withAuthHoc(AssetDetailsPageLoadable)}
          >
            <Route
              path={`${ROUTES.USER.ASSETS.ASSET_DETAILS}`}
              element={withAuthHoc(AssetMainDetailsLoadable)}
            />
            <Route
              path={`${ROUTES.USER.ASSETS.ASSET_DETAILS}${ROUTES.USER.ASSETS.ASSET_HISTORY}`}
              element={withAuthHoc(AssetHistoryLoadable)}
            />
            <Route
              path={`${ROUTES.USER.ASSETS.ASSET_DETAILS}${ROUTES.USER.ASSETS.ASSET_JOB_HISTORY}`}
              element={withAuthHoc(AssetJobHistoryLoadable)}
            />
          </Route>

          <Route
            path={`${ROUTES.USER.SETTINGS.BASE_PATH}${ROUTES.USER.SETTINGS.USERS}`}
            element={withAuthHoc(UsersLoadable)}
          />
          <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
        </Route>
        {/* not found route */}
        <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
      </Routes>
    </>
  );
}

export default AppRoute;
