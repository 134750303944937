import React from 'react';

import { Chip, lighten, Box } from '@mui/material';

import { ReactComponent as ArrowDown } from 'assets/images/tabler-icon/chevron-down.svg';
import SVGIcon from 'common/components/svg-icon/SVGIcon';
import { JobStatus } from 'services/job-service/job.modal';
import { remCalc } from 'theme/utils';

// eslint-disable-next-line import/no-cycle
import { statusColorMapping, getStatusLabel } from './config';

interface IJobStatusBadgeProps {
  status: JobStatus;
  onDropdown?: () => void | undefined;
}
interface IJobStatusIconProps {
  status: JobStatus;
}

function JobStatusBadge({ status, onDropdown }: IJobStatusBadgeProps): JSX.Element {
  const color = statusColorMapping[status];
  const label = getStatusLabel(status);
  return (
    <Chip
      icon={
        <Box
          sx={{ width: '8px', height: '8px', borderRadius: '50%', background: color, mr: '4px' }}
        />
      }
      onClick={onDropdown}
      deleteIcon={
        onDropdown ? (
          <SVGIcon icon={ArrowDown} stroke={color} width='16px' height='16px' />
        ) : undefined
      }
      label={label}
      onDelete={onDropdown}
      sx={{
        background: lighten(color, 0.85),
        color,
        px: '6px',
        fontSize: remCalc(12),
        fontWeight: 600,
        cursor: onDropdown ? 'pointer' : 'auto',
      }}
    />
  );
}

JobStatusBadge.defaultProps = {
  onDropdown: null,
};

export function JobStatusIcon({ status }: IJobStatusIconProps): JSX.Element {
  const color = statusColorMapping[status];
  return (
    <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', background: color, mr: '4px' }} />
  );
}

export default JobStatusBadge;
