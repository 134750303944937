import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  IconButton,
  List,
  Drawer,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  Button,
  Stack,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { ReactComponent as ScanIcon } from 'assets/images/tabler-icon/scan.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/tabler-icon/settings.svg';
import { ReactComponent as SideNavIcon } from 'assets/images/tabler-icon/sidenav.svg';
import SVGIcon from 'common/components/svg-icon/SVGIcon';
import OrgSelector from 'common/layout/navbar/org-selector/OrgSelector';
import { ISideNavLink, SIDE_NAV_LINKS } from 'constants/app.drawer.constants';
import ROUTES from 'constants/routes';
import { useFeatures } from 'hooks/useFeatures';
import useLaunchDark from 'hooks/useLaunchDark';
import usePermission from 'hooks/usePermission';
import { ILaunchDarkModules } from 'services/common/common.modal';
import { ActionType, ModulesType } from 'services/settings-service/settings.modal';
import { useAppDispatch } from 'store/hooks';
import { setIsDrawerOpen } from 'store/reducers/appDrawerSlice';
import { setIsScannerOpen } from 'store/reducers/scannerSlice';
import { remCalc } from 'theme/utils';
import { PRODUCT_LOGO, PRODUCT_NAME } from 'utils/commonConstant';
import { mobileAndTabletCheck } from 'utils/commonUtils';

import { useAppDrawerContentStyles } from './appDrawerStyles';
import { isSettingEnable } from './settings/config';
import SettingsSidenav from './settings/SettingsSidenav';
import SideNavLink from './SideNavLink';

function AppDrawerContent(): JSX.Element {
  const dispatch = useAppDispatch();
  const { isPermissionAvailable } = usePermission();
  const isSettingPermissionAllowed = isPermissionAvailable(
    `${ActionType.manage}:${ModulesType.settings}`,
  );
  const { classes } = useAppDrawerContentStyles();
  const rootEl = useRef(null);
  const theme = useTheme();

  const [settingStatus, updateSettingStatus] = useState<boolean>(false);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { data: features } = useFeatures();

  const handleCloseDrawer = (): void => {
    dispatch(setIsDrawerOpen(false));
  };
  const { getModulePermission } = useLaunchDark([]);
  const isMobile = mobileAndTabletCheck();
  const mobileMatches = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  const isMobileCheckPass = isMobile && mobileMatches;

  useEffect(() => {
    updateSettingStatus(isSettingEnable(pathname));
  }, [pathname, updateSettingStatus]);

  useEffect(() => {
    if (isMobileCheckPass) {
      dispatch(setIsDrawerOpen(false));
    } else {
      dispatch(setIsDrawerOpen(true));
    }
  }, [dispatch, isMobileCheckPass]);

  const getSideNavLink = (): ISideNavLink[] => {
    return SIDE_NAV_LINKS.filter(({ module, path }) => {
      if (path === '/reports' && !features?.data?.features?.reports_url) {
        return null;
      }

      if (path === '/telematics' && !features?.data?.features?.telematics) {
        return false;
      }

      if (module) {
        return isPermissionAvailable(`${ActionType.read}:${module}`);
      }

      return true;
    });
  };

  const handleSettings = (e: React.FormEvent<HTMLElement>): void => {
    e.preventDefault();
    updateSettingStatus((prev) => !prev);
  };

  const handleScan = (): void => {
    dispatch(setIsDrawerOpen(false));
    dispatch(setIsScannerOpen(true));
  };

  return (
    <Box className={classes.root} ref={rootEl}>
      <Drawer
        container={(): Element | null => rootEl?.current}
        variant='persistent'
        open={settingStatus}
        ModalProps={{
          keepMounted: true,
          hideBackdrop: true,
        }}
        sx={{
          display: 'block',
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 230,

            backgroundColor: '#ffffff',
            boxShadow: '0px 15px 25px rgba(0, 0, 0, 0.05)',
          },
        }}
      >
        <SettingsSidenav onClose={handleSettings} />
      </Drawer>

      <Box>
        {isMobileCheckPass && (
          <IconButton
            onClick={handleCloseDrawer}
            sx={{ paddingLeft: '18px', paddingBottom: '15px' }}
          >
            <SVGIcon icon={SideNavIcon} />
          </IconButton>
        )}
      </Box>

      {isMobileCheckPass && (
        <Stack
          direction='row'
          alignItems='center'
          sx={{ p: '8px', border: '1px solid #E5E5E5', borderRadius: '4px', mb: '10px' }}
        >
          <Box className='nav-title ' to={ROUTES.USER.OVERVIEW} component={NavLink}>
            <Stack direction='row' alignItems='center'>
              <img
                src={PRODUCT_LOGO}
                alt={PRODUCT_NAME}
                style={{
                  width: '32px',
                  height: '32px',
                  border: '1px solid #F5F5F5',
                  borderRadius: '6px',
                }}
              />
            </Stack>
          </Box>
          <OrgSelector />
        </Stack>
      )}

      <List
        sx={{ width: '100%', maxWidth: 360, pt: 0, pb: 0, bgcolor: 'background.paper' }}
        component='nav'
        className={classes.sideNavList}
      >
        {getSideNavLink().map((sideNav) => {
          const { label } = sideNav;
          return <SideNavLink key={label} {...sideNav} />;
        })}
        {isSettingPermissionAllowed && (
          <a href='/#' style={{ marginTop: 'auto' }} onClick={handleSettings}>
            <ListItemButton className='side-nav-item'>
              <SVGIcon icon={SettingsIcon} fontSize='small' />
              <ListItemText primary={t('side_nav.settings')} className='nav-link-text' />
            </ListItemButton>
          </a>
        )}
        {isMobileCheckPass && getModulePermission(ILaunchDarkModules.QRScanner) && (
          <Button
            sx={{
              p: '4px 16px',
              fontSize: remCalc(14),
              lineHeight: '20px',
              ml: '16px',
              fontWeight: 600,
              height: {
                xs: '40px',
                md: '48px',
              },
            }}
            startIcon={
              <SVGIcon height='16' width='16' stroke='#FBFF47' icon={ScanIcon} fontSize='small' />
            }
            onClick={handleScan}
            variant='contained'
          >
            Scan Asset
          </Button>
        )}
      </List>
    </Box>
  );
}

export default AppDrawerContent;
