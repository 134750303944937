const ROUTES = {
  HOME: '/',
  NOT_FOUND: '*',
  USER: {
    OVERVIEW: '/overview',
    REPORTS: '/reports',
    NO_PERMISSION: '/no-permission',
    PROFILE: '/profile',
    PROFILE_DETAILS: '/profile-details',
    PROFILE_COMPLETE: '/complete-profile',
    SETTINGS: {
      BASE_PATH: '/settings',
      LOCATION_DETAILS: '/locations/:id',
      ADD_ORGANISATIONS: '/add-organisation',
      USER_DETAILS: '/users/:id',
      EDIT_ORGANISATION: '/edit-organisation/:id',
      ORGANISATIONS_DETAILS: '/organisations',
      LOCATIONS: '/locations',
      USERS: '/users',
      USERS_ASSIGNMENT_HISTORY: '/history',
      ADDITIONAL_FIELDS: '/additional_fields',
      ASSET_CONFIG: '/asset-config',
      GROUPS: '/groups',
      GROUPS_DETAILS: '/groups/:id',
      JOB_TYPES: '/job-types',
      CATEGORIES: '/categories',
      ADD_JOB_ACTIVITY: '/add-job-activity',
      JOB_ACTIVITY: '/job-activity',
      JOB_ACTIVITY_DETAILS: '/job-activity/:id',
      EDIT_JOB_ACTIVITY: '/edit-job-activity/:id',
    },
    ASSETS: {
      BASE_PATH: '/assets',
      ASSET_DETAILS: '/assets/:id',
      ASSET_HISTORY: '/history',
      ASSET_JOB_HISTORY: '/job',
    },
    TELEMATICS: {
      BASE_PATH: '/telematics',
      TELEMATICS_DETAILS: '/telematics/:id',
    },
    PARTS: {
      BASE_PATH: '/parts',
      ARCHIVE: 'archive',
      PARTS_DETAILS: '/parts/:id',
    },
    JOBS: {
      BASE_PATH: '/jobs',
      JOB_DETAILS: '/jobs/:id',
    },
  },
};
export default ROUTES;
