import { Box } from '@mui/material';

import SVGIcon from 'common/components/svg-icon/SVGIcon';

import { ReactComponent as Icon1 } from './icon1.svg';
import { ReactComponent as Icon10 } from './icon10.svg';
import { ReactComponent as Icon11 } from './icon11.svg';
import { ReactComponent as Icon12 } from './icon12.svg';
import { ReactComponent as Icon13 } from './icon13.svg';
import { ReactComponent as Icon14 } from './icon14.svg';
import { ReactComponent as Icon2 } from './icon2.svg';
import { ReactComponent as Icon3 } from './icon3.svg';
import { ReactComponent as Icon4 } from './icon4.svg';
import { ReactComponent as Icon5 } from './icon5.svg';
import { ReactComponent as Icon6 } from './icon6.svg';
import { ReactComponent as Icon7 } from './icon7.svg';
import { ReactComponent as Icon8 } from './icon8.svg';
import { ReactComponent as Icon9 } from './icon9.svg';

const iconConfigMap = {
  icon1: Icon1,
  icon2: Icon2,
  icon3: Icon3,
  icon4: Icon4,
  icon5: Icon5,
  icon6: Icon6,
  icon7: Icon7,
  icon8: Icon8,
  icon9: Icon9,
  icon10: Icon10,
  icon11: Icon11,
  icon12: Icon12,
  icon13: Icon13,
  icon14: Icon14,
  service: '/images/service.svg',
  theft: '/images/theft.svg',
  assembly: '/images/assembly.svg',
  transfer: '/images/transfer.svg',
  recovery: '/images/recovery.svg',
};

export const getJobTypeConfigIcon = (iconType: unknown): JSX.Element => {
  const icon = iconConfigMap[iconType as keyof typeof iconConfigMap] || Icon1;

  if (typeof icon === 'string') {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src={icon} alt={icon} width='16px' height='16px' />
      </Box>
    );
  }

  return <SVGIcon icon={icon} />;
};

export const jobTypeIconList = Object.keys(iconConfigMap);
